"use client";
import { useTheme } from "next-themes";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";

const World = dynamic(() => import("../ui/globe").then((m) => m.World), {
  ssr: false,
});

export function Globe() {
  const { resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  // Mount effect to handle hydration
  useEffect(() => {
    setMounted(true);
  }, []);

  const globeConfig = useMemo(() => ({
    pointSize: 4,
    globeColor: mounted && resolvedTheme === "dark" ? "#000000" : "#324376",
    showAtmosphere: true,
    atmosphereColor: "#ffffff",
    atmosphereAltitude: 0.07,
    emissive: mounted && resolvedTheme === "dark" ? "#324376" : "#324376",
    emissiveIntensity: 0.50,
    shininess: 0,
    polygonColor: "rgba(249, 115, 22, 0.9)",
    ambientLight: "#ffffff",
    directionalLeftLight: "#ffffff",
    directionalTopLight: "#FFFFFF",
    pointLight: "#ffffff",
    arcTime: 1800,
    arcLength: 0.5,
    rings: 2,
    maxRings: 2,
    initialPosition: { lat: 20, lng: 40 },
    autoRotate: true,
    autoRotateSpeed: 0.7,
  }), [mounted, resolvedTheme]); // Add dependencies

  const colors = [
    "#16F933",
    "#ff0b1f",
    "#8b5cf6",
    "#22c55e",
    "#2224c5"
  ];

  const dallasLat = 32.7767;
  const dallasLng = -96.7970;

  const origins = [
    { name: "Hyderabad", lat: 17.3850, lng: 78.4867 },
    { name: "Visakhapatnam", lat: 17.6868, lng: 83.2185 },
    { name: "Guntur", lat: 16.3067, lng: 80.4365 },
    { name: "Tirupati", lat: 13.6288, lng: 79.4192 },
    { name: "Warangal", lat: 18.0000, lng: 79.5833 },
    { name: "Rajahmundry", lat: 17.0005, lng: 81.8040 },
    { name: "Kakinada", lat: 16.9891, lng: 82.2475 },
    { name: "Nellore", lat: 14.4426, lng: 79.9865 },
    { name: "Kurnool", lat: 15.8281, lng: 78.0373 },
    { name: "Bangalore", lat: 12.9716, lng: 77.5946 },
    { name: "Mumbai", lat: 19.0760, lng: 72.8777 },
    { name: "Delhi", lat: 28.6139, lng: 77.2090 },
    { name: "Dubai", lat: 25.2048, lng: 55.2708 },
    { name: "London", lat: 51.5074, lng: -0.1278 },
    { name: "San Francisco", lat: 37.7749, lng: -122.4194 },
    { name: "Los Angeles", lat: 34.0522, lng: -118.2437 },
    { name: "Chicago", lat: 41.8781, lng: -87.6298 },
    { name: "New Jersey", lat: 40.0583, lng: -74.4057 },
    { name: "New York", lat: 40.7128, lng: -74.0060 },
    { name: "Riyadh", lat: 24.7136, lng: 46.6753 },
    { name: "Jeddah", lat: 21.2854, lng: 39.2376 },
    { name: "Sydney", lat: -33.8688, lng: 151.2093 },
    { name: "Melbourne", lat: -37.8136, lng: 144.9631 },
    { name: "Brisbane", lat: -27.4698, lng: 153.0251 },
    { name: "Singapore", lat: 1.3521, lng: 103.8198 },
    { name: "Toronto", lat: 43.6532, lng: -79.3832 },
    { name: "Manchester", lat: 53.4808, lng: -2.2426 },
    { name: "Birmingham", lat: 52.4862, lng: -1.8904 },
    { name: "Kuwait City", lat: 29.3759, lng: 47.9774 },
    { name: "Johannesburg", lat: -26.2041, lng: 28.0473 },
    { name: "Durban", lat: -29.8587, lng: 31.0218 },
    { name: "Auckland", lat: -36.8485, lng: 174.7633 },
    { name: "Berlin", lat: 52.5200, lng: 13.4050 },
    { name: "Munich", lat: 48.1351, lng: 11.5820 },
    { name: "Vancouver", lat: 49.2827, lng: -123.1207 }
  ];

  const sampleArcs = useMemo(() => {
    // Group origins by rough geographical regions
    const regions = {
      asia: origins.filter(o => o.lng > 60 && o.lat < 35),        // India, Singapore, etc.
      middleEast: origins.filter(o => o.lng > 30 && o.lng < 60),  // Dubai, Riyadh, etc.
      europe: origins.filter(o => o.lng < 30 && o.lat > 35),      // London, etc.
      oceania: origins.filter(o => o.lng > 100 && o.lat < 0),     // Australia
      northAmerica: origins.filter(o => o.lng < -30),             // US cities
      africa: origins.filter(o => o.lng > 0 && o.lat < 0)         // South Africa
    };

    // Create pairs of opposite regions to animate together
    const groupedArcs = [
      // Group 1: Asia + North America + Africa
      ...regions.asia.map((origin, i) => ({
        order: i,
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.4,
        color: colors[0]
      })),
      ...regions.northAmerica.map((origin, i) => ({
        order: i,
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.35,
        color: colors[1]
      })),
      ...regions.africa.map((origin, i) => ({
        order: i,
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.45,
        color: colors[2]
      })),

      // Group 2: Middle East + Oceania
      ...regions.middleEast.map((origin, i) => ({
        order: i + Math.max(
          regions.asia.length,
          regions.northAmerica.length,
          regions.africa.length
        ),
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.4,
        color: colors[3]
      })),
      ...regions.oceania.map((origin, i) => ({
        order: i + Math.max(
          regions.asia.length,
          regions.northAmerica.length,
          regions.africa.length
        ),
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.35,
        color: colors[4]
      })),

      // Group 3: Europe
      ...regions.europe.map((origin, i) => ({
        order: i + Math.max(
          regions.asia.length,
          regions.northAmerica.length,
          regions.africa.length
        ) + Math.max(
          regions.middleEast.length,
          regions.oceania.length
        ),
        startLat: origin.lat,
        startLng: origin.lng,
        endLat: dallasLat,
        endLng: dallasLng,
        arcAlt: 0.4,
        color: colors[0] // Back to first color for contrast
      }))
    ];

    return groupedArcs;
  }, []);


  // Handle initial SSR render
  if (!mounted) {
    return null;
  }

  return (
    <div className="relative w-full h-full">
      <div className="w-full h-full">
        <World 
          key={resolvedTheme} // Force re-render on theme change
          data={sampleArcs} 
          globeConfig={globeConfig} 
        />
      </div>
    </div>
  );
}
