import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogDescription,
  } from "@/components/ui/dialog";
  import { ScrollArea } from "@/components/ui/scroll-area";
  import ReactMarkdown from "react-markdown";
  import remarkBreaks from "remark-breaks";
  import { useEffect, useState } from "react";
import { TermsOfService } from "./legal";
  
  export function TermsOfServiceDialog() {
    const [content, setContent] = useState<string>("");
    const tosContent = TermsOfService().content
    useEffect(() => {
        setContent(tosContent)
    }, [])
  
    return (
      <Dialog>
        <DialogTrigger asChild>
          <button className="text-muted-foreground hover:text-foreground transition-colors">
            Terms of Service
          </button>
        </DialogTrigger>
        <DialogContent 
          className="max-w-4xl max-h-[90vh]"
          aria-describedby="terms-content"
        >
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">Terms of Service</DialogTitle>
            <DialogDescription id="terms-content">
              Please read our terms of service carefully before proceeding.
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="h-[calc(90vh-100px)] pr-4">
            <div className="prose prose-sm dark:prose-invert max-w-none">
              <ReactMarkdown
                components={{
                  h1: ({ children }) => (
                    <h1 className="text-2xl font-bold mt-8 mb-4">{children}</h1>
                  ),
                  h2: ({ children }) => (
                    <h2 className="text-xl font-semibold mt-6 mb-3">{children}</h2>
                  ),
                  h3: ({ children }) => (
                    <h3 className="text-lg font-medium mt-4 mb-2">{children}</h3>
                  ),
                  h4: ({ children }) => (
                    <h4 className="text-base font-medium mt-3 mb-2">{children}</h4>
                  ),
                  p: ({ children }) => (
                    <p className="text-sm mb-4 leading-relaxed">{children}</p>
                  ),
                  ul: ({ children }) => (
                    <ul className="list-disc pl-6 space-y-2 mb-4">{children}</ul>
                  ),
                  li: ({ children }) => (
                    <li className="text-sm">{children}</li>
                  ),
                  a: ({ href, children }) => (
                    <a 
                      href={href} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      {children}
                    </a>
                  ),
                  strong: ({ children }) => (
                    <strong className="font-semibold">{children}</strong>
                  )
                }}
                remarkPlugins={[remarkBreaks]}
              >
                {content}
              </ReactMarkdown>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    );
  }