"use client";
import { motion } from "framer-motion";
import { useTheme } from "next-themes";
import { useEffect, useRef, useState } from "react";

const GRADIENT_COLORS = {
  dark: [
    { offset: "0%", color: "#dc2626" },     // Red
    { offset: "20%", color: "#FFD700" },    // Gold
    { offset: "30%", color: "#FDB813" },    // Darker Gold
    { offset: "40%", color: "#dc2626" },    // Red
    { offset: "60%", color: "#FFD700" },    // Gold
    { offset: "70%", color: "#FDB813" },    // Darker Gold
    { offset: "80%", color: "#dc2626" },    // Red
    { offset: "100%", color: "#dc2626" },   // Red
  ],
  light: [
    { offset: "0%", color: "#e63946" },     // Vivid Red
    { offset: "20%", color: "#f06543" },    // Bright Red
    { offset: "30%", color: "#ff7f51" },    // Popping Red-Orange
    { offset: "40%", color: "#e63946" },    // Vivid Red
    { offset: "60%", color: "#f06543" },    // Bright Red
    { offset: "70%", color: "#ff7f51" },    // Popping Red-Orange
    { offset: "80%", color: "#e63946" },    // Vivid Red
    { offset: "100%", color: "#e63946" }     // Darker Red
  ]
} as const;

function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
) {
  let timeout: NodeJS.Timeout;
  
  const debouncedFunc = (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };

  debouncedFunc.cancel = () => {
    clearTimeout(timeout);
  };

  return debouncedFunc;
}

const getEffectiveTheme = (theme: string | undefined) => {
  // If theme is explicitly set to dark or light, use that
  if (theme === 'dark' || theme === 'light') {
    return theme;
  }

  // For 'system' or undefined, check system preference
  if (typeof window !== 'undefined') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches 
      ? 'dark' 
      : 'light';
  }

  // Default to light if we can't determine
  return 'light';
};

export const TextHoverEffect = ({
  text,
  duration = 0,
  automatic = false,
  highlightText,
  highlightColor = "#FF0000",
}: {
  text: string;
  duration?: number;
  automatic?: boolean;
  highlightText?: string;
  highlightColor?: string;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [mounted, setMounted] = useState(false);
  const { theme } = useTheme();
  const currentTheme = getEffectiveTheme(theme);
  const colors = GRADIENT_COLORS[currentTheme];

  useEffect(() => {
    setMounted(true);
  }, []);



  if (!mounted) {
    return (
      <span className="invisible">
        {text}
        {highlightText}
      </span>
    );
  }

  return (
    <>
      <span className="sr-only">{text}</span>
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox="0 0 400 80"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className="select-none h-20 sm:h-32 md:h-32 lg:h-40"
      style={{
        contain: 'paint',
        contentVisibility: 'auto'
      }}
    >
      <defs>
        <linearGradient
          id={`textGradient-${text}`}
          gradientUnits="objectBoundingBox"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
        >
          {colors.map(({ offset, color }) => (
            <stop key={offset} offset={offset} stopColor={color} />
          ))}
        </linearGradient>

      </defs>

      <g transform="translate(200, 40)">
        <motion.text
          textAnchor="middle"
          dominantBaseline="middle"
          className="font-bold text-6xl sm:text-7xl md:text-6xl lg:text-6xl"
          style={{
            fill: 'transparent',
            stroke: `url(#textGradient-${text})`,
            strokeWidth: '0.8',  // Reduced strokeWidth
            paintOrder: 'stroke',
            willChange: 'transform', // Optimize for animations
          }}
          initial={{ strokeDashoffset: 1600, strokeDasharray: 1600 }} // Reduce initial offset for better performance
          animate={{ strokeDashoffset: 0 }}
          transition={{ 
            duration: 2.5,  // Reduced duration
            ease: "easeInOut",
            delay: 1,  // Reduced delay
          }}
        >
          {text}
        </motion.text>
      </g>
    </svg>
    </>
  );
};
